label {
  font-weight: 500;
  margin-bottom: rem-calc(10px);
}

.form-col {
  display: flex;
  flex-wrap: wrap;
  margin: rem-calc(0 -10px);
  > * {
    flex: 1 1 auto;
    padding: rem-calc(0 10px);
  }

  > * + * {
    margin-top: rem-calc(15px);
  }


  @media (min-width: $screen-md) {
    margin-bottom: 0;

    > * + * {
      margin-top: 0;
    }
  }
}