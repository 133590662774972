#page {
  overflow: hidden;
}

img {
  max-width: 100%;
  height: auto;
}

.svg-sprite {
  height: 0;
  width: 0;
  overflow: hidden;
  opacity: 0;
  position: absolute;
}

a {
  font-weight: 600;
}