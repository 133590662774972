
$drapeau-france-bleu: rgb(0, 75, 164);
$drapeau-france-blanc: #fff;
$drapeau-france-rouge: rgb(206, 17, 38);
$drapeau-height: rem-calc(8px);
$drapeau-width: rem-calc(150px);

.copyright-cmonsite::before,
.copyright-cmonsite::after {
  content: '';
  height: $drapeau-height;
  width: $drapeau-width;
  display: inline-block;
  margin: rem-calc(20px);
  background-image: linear-gradient(to left, $drapeau-france-bleu 0%, $drapeau-france-bleu 33.33%, #FFF 33.33%, #FFF 66.66%, $drapeau-france-rouge 66.66%, $drapeau-france-rouge 100%);
  border: 1px solid rgb(232, 232, 232);
  @media (min-width: $screen-md) {
    margin: rem-calc(0 20px);
  }
}

.copyright-cmonsite {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  @media (min-width: $screen-md) {
    flex-direction: row;
  }
}



#footer {
  box-shadow: 1px 4px 24px rgba(0,0,0,.17);
  background-color: #fff;
  margin-top: rem-calc(50px);
  padding-top: rem-calc(40px);
  font-size: rem-calc(15px);

  a {
    color: #434B37;
    font-weight: 500;
  }

}


.footer-link {
  font-size: rem-calc(12px);
  margin-bottom: rem-calc(40px);
  text-align: center;
  ul {
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    list-style: none;
    padding: 0;
  }
  li {
    flex: 1 1 auto;
    padding: rem-calc(0 5px);
    @media (min-width: $screen-md) {
      flex: 0 0 auto;
    }
  }
  @media (min-width: $screen-md) {
    text-align: left;
    li + li {
      border-left: 1px solid #434b37;
    }
  }

}


.follow {
  margin-top: rem-calc(15px);

  @media (min-width: $screen-md) {
    margin-top: 0;
  }
}

.follow__title {
  font-weight: 600;
  text-align: center;
}

.follow__list {
  display: flex;
  justify-content: center;
  align-items: center;
  > * {
    flex: 0 0 auto;
    margin-right: rem-calc(15px);
  }

  a, svg {
    display: block;
  }
}


.footer-infos {
  margin-bottom: rem-calc(50px);
  text-align: center;
  @media (min-width: $screen-md) {
    text-align: left;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
}
