@font-face {
  font-family: "Montserrat";
  font-weight: 1 999;
  font-style: normal;
  src:
          url("../font/Montserrat/variable/Montserrat-VF.woff2") format("woff2-variations");
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  font-weight: 400;
  font-style: normal;
  src:
          url("../font/Montserrat/variable/Montserrat-VF.woff2") format("woff2-variations"),
          url("../font/Montserrat/webfonts/Montserrat-Regular.woff2") format("woff2");
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  font-weight: 700;
  font-style: normal;
  src:
          url("../font/Montserrat/variable/Montserrat-VF.woff2") format("woff2-variations"),
          url("../font/Montserrat/webfonts/Montserrat-Bold.woff2") format("woff2");
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  font-weight: 400;
  font-style: italic;
  src:
          url("../font/Montserrat/variable/Montserrat-Italic-VF.woff2") format("woff2-variations"),
          url("../font/Montserrat/webfonts/Montserrat-Italic.woff2") format("woff2");
  font-display: swap;
}
