
.main-container {
  padding-top: rem-calc(30px);

  &--index {
    padding-top: 0;
  }
}

.section {
  position: relative;

  strong {
    font-weight: 600;
  }

  .section__bg {
    position: absolute;
    z-index: -1;
    right: 0;
    left: 0;
    top: 0;

    svg {
      width: 2100px;
      margin-left: 50%;
      transform: translateX(-50%);
      max-width: none;
    }
  }
}

svg {
  max-width: 100%;
  height: auto;
}

.section--padding {
  &-100 {
    padding-top: rem-calc(30px);
    padding-bottom: rem-calc(30px);
    @media (min-width: $screen-md) {
      padding-top: rem-calc(100px);
      padding-bottom: rem-calc(100px);
    }
  }
  &-50 {
    padding-top: rem-calc(30px);
    padding-bottom: rem-calc(30px);
    @media (min-width: $screen-md) {
      padding-top: rem-calc(50px);
      padding-bottom: rem-calc(50px);
    }
  }
}

.h-with-icon {
  display: flex;
  align-items: center;

  > * {
    flex: 0 1 auto;
  }

  svg {
    flex: 0 0 auto;
    margin-right: rem-calc(15px);
  }

}
