/* -------------------------------------------------------------------------- *\
 * Necessary styling for the dialog to work
 * -------------------------------------------------------------------------- */
.dialog {
  z-index: 9999;
  position: relative;
}

.dialog[aria-hidden="true"] {
  display: none;

}

/* -------------------------------------------------------------------------- *\
 * Styling to make the dialog look like a dialog
 * -------------------------------------------------------------------------- */

.dialog-overlay {
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.66);
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.dialog-content {
  background-color: rgb(255, 255, 255);
  z-index: 3;
  position: fixed;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -20%);
}

.dialog-document {

}

/* -------------------------------------------------------------------------- *\
 * Extra dialog styling to make it shiny
 * -------------------------------------------------------------------------- */

.dialog-content {
  max-width: calc(100% - 10px);
  width: 600px;
  border-radius: 2px;
  overflow: auto;
  max-height: calc(100% - 5px);
  @media screen and (min-width: 700px) {
    max-width: 90%;
    max-height: calc(100% - 60px);
  }

  iframe {
    width: 100%;
    height: 500px;
    border: none;
  }
}


.dialog-document {
  padding: 2em;
  overflow: hidden;
}


.dialog-overlay {
  background-color: rgba(43, 46, 56, 0.9);
}

.dialog h1 {
  margin: 0;
  font-size: 1.25em;
}

.dialog-close {
  position: absolute;
  top: 0.5em;
  right: 0.5em;
  border: 0;
  padding: 0;
  background-color: transparent;
  font-weight: bold;
  font-size: 1.25em;
  width: 1.2em;
  height: 1.2em;
  text-align: center;
  cursor: pointer;
  transition: 0.15s;
  line-height: 1;
}

@media screen and (min-width: 700px) {
  .dialog-close {
    top: rem-calc(28px);
    right: rem-calc(24px);
  }
}

// Scale up the modal
@media (min-width: $screen-sm-min) {
  // Automatically set modal's width for larger viewports
  .dialog-content {
    width: $modal-md;
    margin: 30px auto;
  }

  // Modal sizes
  .modal-sm {
    width: $modal-sm;
  }
}

@media (min-width: $screen-md-min) {
  .modal-lg {
    width: $modal-lg;
  }
}

.dialog {

  * {
    box-sizing: border-box;
  }

  &--confirm {
    .dialog-body {
      text-align: center;
    }
  }

  .group-button {
    text-align: center;
    padding-top: 15px;

    .btn {
      margin: 0 15px;
      font-size: 16px;
      padding: 6px 24px;
      font-weight: bold;

    }
  }

}
