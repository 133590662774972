// Core variables and mixins
@import "front/main_mixin";
@import "front/variables";

// Typographie
@import "admin/fonts";

@import "bootstrap/mixins";
// Reset and dependencies
@import "bootstrap/normalize";

// Core CSS
@import "bootstrap/scaffolding";
@import "bootstrap/type";
@import "bootstrap/code";
@import "bootstrap/grid";
//@import "bootstrap/tables";
@import "bootstrap/forms";
@import "bootstrap/input-groups";
@import "bootstrap/buttons";
@import "bootstrap/pagination";
@import "bootstrap/progress-bars";
@import "bootstrap/alerts";


// Utility classes
@import "bootstrap/utilities";
@import "bootstrap/responsive-utilities";


@import "cmonsite-v6/variables";


@import "front/main_global";

@import "front/structure/header";
@import "front/structure/main";
@import "front/structure/footer";


@import "front/component/block-style";
@import "front/component/button";
@import "front/component/dialog";
@import "front/component/form";
@import "front/component/modal-connect";
@import "front/component/alert";



// VENDOR
@import "validationEngine.jquery";
@import "waitMe";

@import "front/vendor/fancybox";


@import "vendor/tarteaucitron/tarteaucitron";

.grecaptcha-badge{
  display: none;
}



