
.navigation-front-mobile {
  margin-left: rem-calc(15px);
  @media (min-width: $screen-md) {
    display: none;
  }


  a {
    position: relative;
    color: #444444;
    display: inline-block;
    font-family: "GandhiSansBold", arial, sans-serif;
    font-size: 20px;
    padding: 3px 10px;
    text-align: center;
    text-decoration: none;
    height: 25px;
    width: 22px;


    &::after {
      content: '';
      position: absolute;
      display: block;
      width: 100%;
      height: 2px;
      background: #000;
      top: 0;
      left: 0;
      box-shadow: 0 7px 0 #000, 0 14px 0 #000;
    }

    &:hover {
      text-decoration: none;
    }

    &.actif, :active {
    }
  }
}


.nav-mobile {
  display: block;
  width: 90%;
  padding: 0;
  top: 0;
  z-index: 100;
  left: 0;
  position: fixed;
  background-color: #fff;
  margin-top: 0;
  transform: translateX(-100%);

  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;

  text-align: center;
  line-height: 3.6;
  padding-top: rem-calc(40px);

  @media (min-width: $screen-md) {
    display: none;
  }

  li {
    z-index: auto;
  }

  .show-menu & {
    transform: translateX(0);
    box-shadow: 0 0 64px #000;
  }
}

.mobile.show-menu {
  overflow: hidden;
}


.close-menu {
  display: none;

  .show-menu & {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 90;
    display: block;
  }
}

.header-principal {
  background: rgba(249, 249, 249, 0.7);

  .nav-infos, .nav-account {
    display: none;
  }

  @media (min-width: $screen-md) {
    .nav-infos, .nav-account {
      display: flex;
    }
  }

}

.header-principal__content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: rem-calc(20px 0 16px);
}


.nav-infos ul {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  font-weight: 500;
  font-size: rem-calc(14px);
  @media (min-width: $screen-md) {
    font-size: rem-calc(16px);
  }
}


.nav-infos li {
  padding: rem-calc(0 15px);
}

.nav-infos a {
  color: #000;
  text-decoration: none;
  &::after {
    content: '';
    display: block;
    margin: 0 auto;
    height: 3px;
    width: 0;
    background: #90ad00;
    opacity: .7;
    transition: width .5s ease;
  }

  &:hover {
    &::after {
      width: 100%;
    }
  }
}


.nav-account ul {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
}


.nav-account .btn-secondary > span {
  display: none;
  @media (min-width: $screen-md) {
    display: inline;
  }
}


@media (min-width: $screen-md) {
  .nav-account li + li {
    margin-left: rem-calc(15px);
  }
}
.nav-infos__exemple {
  display: none;
  @media (min-width: $screen-md) {
    display: block;
  }
  .nav-mobile & {
    display: block;
  }
}

.nav-mobile .nav-infos ul,
.nav-mobile .nav-account ul {
  flex-direction: column;
  font-size: rem-calc(16px);
}


.logo {
  margin: 0 auto;
  @media (min-width: $screen-md) {
    margin: 0;
  }
}