.modal-connect {
  .dialog-overlay {
    background-color: rgba(255, 255, 255, 0.62);
  }

  .dialog-content {
    box-shadow: 1px 4px 24px rgba(0, 0, 0, 0.17);
    border-radius: 5px;
  }

}

.modal-connect__content {

  max-width: 260px;
  margin: 0 auto;
  text-align: center;


  form {
    text-align: left;
    margin-top: 30px;
    margin-bottom: 30px;
  }
}


.modal-connect__create {
  text-align: center;
  padding: 15px 30px 30px;
  background-color: #F5FAFF;
  margin: 0 -28px -30px;
  margin-top: 20px;
}


.well--connect {
  padding: 20px 28px;
}
