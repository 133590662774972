
.btn {
  padding: 10px 20px 11px;
  line-height: 1;
  letter-spacing: 0.05em;
}

.btn-secondary {
  @include button-variant($btn-secondary-color, $btn-secondary-bg, $btn-secondary-border);
}

.btn-hollow {
  @include button-variant($btn-primary-bg, #fff, $btn-primary-bg);
}

.btn-lg {
  // line-height: ensure even-numbered height of button next to large input
  @include button-size(
                  rem-calc(10px),
                  rem-calc(30px),
                  rem-calc(17px),
                  1,
                  rem-calc(5px)
  );
}


.input-group-btn > .btn svg {
  max-width: none;
}
